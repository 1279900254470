<template>
  <q-form ref="editForm">
    <c-card title="LBLDETAIL" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn
            v-show="printable"
            label="출력"
            icon="print"
            @btnClicked="printSelfCheck" />
          <c-btn 
            v-show="editable && !disabled" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="selfCheck"
            :mappingType="mappingType"
            label="LBLSAVEALL" 
            icon="save"
            @beforeAction="save"
            @btnCallback="saveCallback" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-text
            :editable="false"
            :disabled="disabled"
            :required="true"
            label="점검명"
            name="selfCheckName"
            v-model="selfCheck.selfCheckName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            :required="true"
            :disabled="disabled"
            :editable="editable"
            default="today"
            label="자체점검일"
            name="selfCheckDate"
            :start="popupParam.startDt"
            :end="popupParam.endDt"
            v-model="selfCheck.selfCheckDate"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            :required="true"
            :disabled="disabled"
            :editable="editable"
            :range="true"
            :minuteStep="10"
            type="time"
            default="today"
            label="점검시간"
            name="time"
            v-model="selfCheck.time"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept
            :required="true"
            :disabled="disabled"
            :editable="editable"
            label="주관부서"
            name="deptCd"
            v-model="selfCheck.deptCd">
          </c-dept>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-field
            :required="true"
            :editable="editable"
            :disabled="disabled"
            type="user"
            label="점검자"
            name="selfCheckUserId"
            v-model="selfCheck.selfCheckUserId"/>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant
            :required="true"
            :disabled="disabled"
            :editable="editable"
            type="edit" 
            name="plantCd" 
            v-model="selfCheck.plantCd" />
        </div>
      </template>
    </c-card>

    <c-table
      ref="table"
      class="q-mt-sm"
      title="점검결과 목록"
      tableId="selfCheckItemResult"
      :editable="editable&&!disabled"
      :columns="grid.columns"
      :data="selfCheck.selfCheckItemResults"
      :gridHeight="grid.height"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
    >
      <!-- gridHeight="550px" -->
      <template slot="suffixTitle">
        <font class="text-negative" style="font-size:0.8em;font-weight:300;">
          <div>
          1. 비고란에는 자체점검 시 조치완료된 사항 또는 재점검이 필요한 사항을 적습니다.
          </div>
          <div>
          2. 유해화학물질 취급시설 자체점검을 하려는 자는 양식의 점검 항목이 모두 포함된 별도의 서식을 사용할 수 있으며, 점검 항목이 모두 포함되어 있음을 명확하게 알 수 있도록 표기해야 합니다.)
          </div>
        </font>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'hazard-facility-self-check-detail',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          hcfHazardFacilitySelfCheckId: '',
          facilityCd: '',
          selfCheckDate: '',
          startDt: '',
          endDt: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      selfCheck: {
        hcfHazardFacilitySelfCheckId: '',  // 유해화학자재 취급시설 자체점검 번호
        plantCd: null,  // 사업장코드
        selfCheckName: '',  // 점검명
        facilityCd: '',  // 시설 코드
        facilityNo: '',  // 시설관리번호
        facilityName: '',  // 시설명
        hcfFacilityTypeName: '',  // 시설유형
        selfCheckDate: '',  // 자체점검일
        startTime: '',  // 점검시작시간
        endTime: '',  // 점검종료시간
        time: [], // 점검시간
        deptCd: '',  // 주관부서 코드
        deptName: '',  // 주관부서명
        selfCheckUserId: '',  // 점검자id
        selfCheckUserName: '',  // 점검자
        hcfSelfCheckCompleteFlag: 'N',  // 점검완료여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        selfCheckItemResults: [], // 자체점검 항목 결과
      },
      grid: {
        columns: [],
        data: [],
        height: '600px'
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      editable: true,
      printUrl: '',
      detailUrl: '',
      itemResultUrl: '',
      saveUrl: '',
      completeUrl: '',
      deleteUrl: '',
      isSave: false,
      isComplete: false,
      mappingType: 'POST',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.selfCheck.hcfSelfCheckCompleteFlag === 'Y'
    },
    deleteable() {
      return this.popupParam.hcfHazardFacilitySelfCheckId && !this.disabled
    },
    printable() {
      return this.popupParam.hcfHazardFacilitySelfCheckId
    },
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - 220);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.printUrl = selectConfig.env.facilitySelfCheck.print.url
      this.detailUrl = selectConfig.env.facilitySelfCheck.get.url
      this.itemResultUrl = selectConfig.env.facilitySelfCheck.item.url
      this.saveUrl = transactionConfig.env.facilitySelfCheck.insert.url
      this.deleteUrl = transactionConfig.env.facilitySelfCheck.delete.url
      this.completeUrl = transactionConfig.env.facilitySelfCheck.complete.url

      // code setting
      this.$comm.getComboItems('HCF_ABNORMALITY_CD').then(_result => {
        this.grid.columns = [
          {
            name: 'selfCheckItemName',
            field: 'selfCheckItemName',
            label: '점검항목',
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'hcfAbnormalityCd',
            field: 'hcfAbnormalityCd',
            label: '이상유무',
            align: 'center',
            style: 'width:100px',
            type: 'select',
            comboItems: _result,
            required: true,
            setHeader: true,
            sortable: false,
          },
          {
            name: 'remark',
            field: 'remark',
            label: 'LBLREMARK',
            align: 'left',
            style: 'width:200px',
            type: 'textarea',
            sortable: false,
          },
        ]
      });
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.hcfHazardFacilitySelfCheckId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.hcfHazardFacilitySelfCheckId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$set(_result.data, 'time', [_result.data.startTime, _result.data.endTime])
          this.$_.extend(this.selfCheck, _result.data);

          if(!this.selfCheck.selfCheckUserId) {
            this.$set(this.selfCheck, 'selfCheckUserId', this.$store.getters.user.userId)
          }
        },);
      } else {
        /**
         * 등록인 경우 점검결과 값을 불러온다
         */
        this.$http.url = this.itemResultUrl
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.forEach(_result.data, item => {
            this.$set(item, 'editFlag', 'C')
            this.$set(item, 'regUserId', this.$store.getters.user.userId)
          })
          this.$set(this.selfCheck, 'selfCheckItemResults', _result.data);
          if (this.popupParam.facilityCd) {
            this.$set(this.selfCheck, 'facilityCd', this.popupParam.facilityCd)
          }
          if (this.popupParam.selfCheckDate) {
            this.$set(this.selfCheck, 'selfCheckDate', this.popupParam.selfCheckDate)
          }
          this.$set(this.selfCheck, 'selfCheckUserId', this.$store.getters.user.userId)
          this.$set(this.selfCheck, 'selfCheckUserName', this.$store.getters.user.userName)
          this.$set(this.selfCheck, 'time', ['08:00', '12:00'])
        },);
      }
    },
    facilityInfo(data) {
      if (data) {
        this.$set(this.selfCheck, 'facilityNo', data.facilityNo)
        this.$set(this.selfCheck, 'facilityName', data.facilityName)
        this.$set(this.selfCheck, 'hcfFacilityTypeName', data.hcfFacilityTypeName)
      } else {
        this.$set(this.selfCheck, 'facilityNo', '')
        this.$set(this.selfCheck, 'facilityName', '')
        this.$set(this.selfCheck, 'hcfFacilityTypeName', '')
      }
    },
    /* eslint-disable no-unused-vars */
    save() {
      if (this.popupParam.hcfHazardFacilitySelfCheckId) {
        this.mappingType = 'PUT'
        this.saveUrl = transactionConfig.env.facilitySelfCheck.update.url
      } else {
        this.mappingType = 'POST'
        this.saveUrl = transactionConfig.env.facilitySelfCheck.insert.url
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result && this.$comm.validTable(this.grid.columns, this.selfCheck.selfCheckItemResults)) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.selfCheck.regUserId = this.$store.getters.user.userId
              this.selfCheck.chgUserId = this.$store.getters.user.userId
              this.selfCheck.startTime = this.selfCheck.time[0]
              this.selfCheck.endTime = this.selfCheck.time[1]

              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.popupParam.hcfHazardFacilitySelfCheckId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    complete() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result && this.$comm.validTable(this.grid.columns, this.selfCheck.selfCheckItemResults)) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGCOMPLETE', // 완료하시겠습니까?,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.selfCheck.regUserId = this.$store.getters.user.userId
              this.selfCheck.chgUserId = this.$store.getters.user.userId
              this.selfCheck.startTime = this.selfCheck.time[0]
              this.selfCheck.endTime = this.selfCheck.time[1]
              this.selfCheck.hcfSelfCheckCompleteFlag = 'Y'

              this.isComplete = !this.isComplete
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          this.selfCheck.hcfSelfCheckCompleteFlag = 'N'
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeCallback(result) {
      this.popupParam.hcfHazardFacilitySelfCheckId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    removeSelfCheck() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.hcfHazardFacilitySelfCheckId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    printSelfCheck() {
      let thisVue = this;
      this.$http.url = this.$format(this.printUrl, this.popupParam.hcfHazardFacilitySelfCheckId);
      this.$http.type = 'GET';
      this.$http.request(
        _result => {
          let fileOrgNm = '시설 자체점검_' + this.selfCheck.selfCheckName + '_' + this.selfCheck.selfCheckDate + '.docx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    },
  }
};
</script>